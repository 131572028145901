import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loaded;
  currentUser;
  invitesall=[];invitesvalid=[];

  btnname = "Check"; emailinput; verifyemailinput;
  bRegistration;
  userExist;
  coin = "BNB";
  payvalue1=0.05;
  payvalue2=0.08;

  payvalue = this.payvalue2;

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    public dataService: DataService) { }

  async ngOnInit() {

    this.dataService.apibinance(environment.apibinance + "BNB" + "BUSD").subscribe(async data => {

      if (data && data['price']) {

        let tknPrice = parseFloat(parseFloat(data['price'].toString()).toFixed(2));

        // this.payvalue1 = parseFloat(parseFloat((0.15 / tknPrice).toString()).toFixed(4));
        // this.payvalue2 = parseFloat(parseFloat((0.25 / tknPrice).toString()).toFixed(4));

        this.payvalue1 = parseFloat(parseFloat((15 / tknPrice).toString()).toFixed(4));
        this.payvalue2 = parseFloat(parseFloat((25 / tknPrice).toString()).toFixed(4));

        this.payvalue = this.payvalue2;

      }
      else {
        alert("Error API price Binance!");
        return;
      }
    });

    this.dataService.bwallet = 0;
    this.dataService.bnetwork = 0;
    this.dataService.bbalance = 0;
    this.dataService.bapprove = 0;

    let temp_ = localStorage.getItem("cryptoaz_invitecode");
    if (temp_ && temp_ != '') {
      this.dataService.invitecode = temp_;
    }

    this.dataService.getList(this.dataService.tableusers).subscribe((data) => {
      if(data){
        data.forEach(element => {
          if(element['coderef'] && this.dataService.invitecode && 
          this.dataService.invitecode == element['coderef'])
          this.dataService.inviteaddress = element['id']
        });

        if (this.dataService.inviteaddress.toLowerCase() != this.dataService.owneraddress.toLowerCase())
        this.payvalue = this.payvalue1;
      }
    })

    if (!this.dataService.coinbase){
      return;
    }

    // this.userExist = await this.dataService.userExist();
    // if (this.userExist){
    //   this.dataService.bwallet = 2;
    //   this.dataService.bnetwork = 2;
    //   this.dataService.bbalance = 2;
    //   this.dataService.bapprove = 2;
    // }
  }

  
  async ngAfterViewInit() {
    //await this.dataService.initweb3();

    let self = this;
    this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe((data) => {
      if (data){
        this.currentUser = data;

        // this.dataService.saveItem(this.dataService.tableusers, {id: this.currentUser.uid, coderef: false} ).then((data) => {

        // })

      if (!this.currentUser['coderef']){

        this.currentUser['coderef'] = this.currentUser.uid.substring(2,7)+UUID.UUID().substring(1,7);
        this.dataService.saveItem(this.dataService.tableusers, {id: this.currentUser.uid, coderef: this.currentUser['coderef']} ).then((data) => {

        })
      }

      if (this.currentUser['email']){

        this.emailinput = this.currentUser['email'];
        this.dataService.userEmail = this.currentUser['email'];
        this.verifyemailinput = true;

        this.dataService.bwallet = 2;
        this.dataService.bnetwork = 2;
        this.dataService.bbalance = 2;
        this.dataService.bapprove = 2;
      }

      this.loaded = true;
      }
    })
  }

  logout() {
    this.afAuth.signOut();
    localStorage.removeItem('mlm_currentUser');
    this.router.navigate(['/auth/login']);
  }

  copyCode() {

    var tempInput = <HTMLInputElement>document.createElement("input");
    tempInput.setAttribute('style', "position: absolute; left: -1000px; top: -1000px");
    tempInput.value = "https://admin.tgelist.com/invite/"+this.currentUser['coderef'];

    document.body.appendChild(tempInput);
    tempInput.select();
    const copyStatus = document.execCommand("copy");
    document.body.removeChild(tempInput);

    if (copyStatus) {
      document.getElementsByClassName('copy-bedge')[0]
        .classList.add('copy-bedge-show')

      setTimeout(() => {
        document.getElementsByClassName('copy-bedge')[0].classList.remove('copy-bedge-show')
      }, 2000)
    }

  }

  async check(){

    this.btnname = "Checking...";

    this.dataService.bwallet = 0;
    this.dataService.bnetwork = 0;
    this.dataService.bbalance = 0;
    this.dataService.bapprove = 0;

    try {
      await this.dataService.initweb3();

      this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe(async (data) => {
        if (data && data['email']){
          this.dataService.userEmail = data['email'];
          this.emailinput = data['email'];
          this.verifyemailinput = true;
        }
        else {
  
        }
      })

      this.userExist = await this.dataService.userExist();
      if (this.userExist){
        this.dataService.bwallet = 2;
        this.dataService.bnetwork = 2;
        this.dataService.bbalance = 2;
        this.dataService.bapprove = 2;
        return;
      }

      if (this.dataService.coinbase && this.dataService.userRegistered){
        this.dataService.bwallet = 2;
        this.dataService.bnetwork = 1;
      }
      else {
        return;
      }

      this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (!data){
          this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase})
        }
        else {
          this.dataService.userEmail = data['email'];
          this.emailinput = data['email']
        }
      })
  
      if (parseInt(this.dataService.networkid) != parseInt(environment.chain)){
        await this.dataService.setnetworkinit();
      }
  
      if (parseInt(this.dataService.networkid) == parseInt(environment.chain)){
        this.dataService.bnetwork = 2;
        this.dataService.bbalance= 1;
      }
      else {
        return;
      }

  
      // let balancebusd = await this.dataService.web3.eth.getBalance(this.dataService.coinbase);
      // if (balancebusd >= this.payvalue*1e18){
        this.dataService.bbalance = 2;
        this.dataService.bapprove= 1;
      // }
      // else {
      //   return;
      // }

      // let balancebusd = await this.dataService.contractBusd.methods.balanceOf(this.dataService.coinbase).call();
      // if (balancebusd >= this.payvalue){
      //   this.dataService.bbalance = 2;
      //   this.dataService.bapprove= 1;
      // }
      // else {
      //   return;
      // }

      // let allow = await this.dataService.tokenContract.methods.atoken(this.dataService.coinbase, environment.contractBusd.address).call();
      // if (allow > 0) {   
      //   this.dataService.bApproved = true; 
      //   this.dataService.bapprove = 2;
      // }
      // else {
      //   await this.dataService.approve();
      //   if (this.dataService.bApproved){
      //     this.dataService.bapprove = 2;
      //   }
      // }

      this.dataService.bapprove = 2;
    }
    catch(ex){
      delete this.dataService.coinbase;
      //alert('Error:'+ex.message)
    }
    finally{
      this.btnname = "Check";
    }


  }

  async instance() {

    // 	if (!contractToken) {
    // 	  alert("Not contract token");
    // 	  return;
    // 	}
      
    // 	if (!bApprove) {
    // 	  alert("First approve!");
    // 	  return;
    // 	}

    if (!this.dataService.coinbase) {
      return;
    }
      
      if (!this.emailinput || this.emailinput == '') {
        return;
      }

      var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (!this.emailinput.match(validRegex)) { 
        this.verifyemailinput = false;
        return;    
      }

      this.verifyemailinput = true;

      this.btnname = "Register...";
      this.bRegistration = true;
      
      this.userExist = await this.dataService.userExist();
      if (this.userExist){
        this.bRegistration = false;
        await this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, email: this.emailinput})
        this.router.navigate(['/tools']);
        return;
      }
      await this.dataService.tokenContract.methods
      .registration(this.dataService.inviteaddress, environment.contractBusd.address)
      .send({ from: this.dataService.coinbase, value: this.payvalue*1e18,  gasLimit: 300000 })
      .on("receipt", async (data) => {
        this.bRegistration = false;
        this.afterRegistration(data);
      })
      .on("error", (error) => {
        this.bRegistration = false;
      })
      
     } 
     
     async afterRegistration(data){
      data['date'] = new Date();
      data['userAddress'] = this.dataService.coinbase;
      data['sponsorAddress'] = this.dataService.inviteaddress;
      data['contractAddress'] = this.dataService.tokenContract.addressbsc;

      await this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, date: new Date(),  uid: this.dataService.coinbase, email: this.emailinput})
  
      let temp_ = JSON.stringify(data);
      temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
      this.dataService.saveItem(this.dataService.tableregister, temp_);
  
      await this.dataService.initweb3();
      this.router.navigate(['/tools']);
      //document.getElementsByTagName('body')[0].click();
      //window.location.href = window.location.origin + '/dashboard/dashboard01';
    }

}
