import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth'

import { AuthenticationService } from '../../core/services/auth.service';
import {BaseService} from '../../pages/_services/base.service';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [BaseService]
})
export class TopbarComponent implements OnInit {

  listall = [];
  projectsToday: Array<{}>;
  projectsWeek: Array<{}>;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  user;admin;username='profil';
  entity = "projects";

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private router: Router, 
    private readonly auth: AngularFireAuth,
    private authService: AuthenticationService,
    private service: BaseService,
    private dataService: DataService) { }

  ngOnInit() {
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;

    this.user = JSON.parse(localStorage.getItem('mlm_currentUser')); //this.authService.currentUser();
    if (this.user){
      this.username = this.user['email'];   
      
      let tempSubscription = this.dataService.getList(this.entity).subscribe((data) => {
        tempSubscription.unsubscribe();
  
        let date1 = new Date();  
        this.listall = data.filter(s => {
          return true;
          //return new Date(s['date']) >= date1
        });
        this.filterCurrentDay(this.listall)
        this.filterCurrentWeek(this.listall)
      })
    }
  }


  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout0() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  logout(): Promise<unknown> {
		return this.auth.signOut().then(() => {
			this.router.navigate(['account/login']);
		})
	}

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.projectsToday = [];
  }

  filterCurrentDay(list){
		let date1 = new Date();

		// if (this.type != 'all'){
		// 	list = this.filterCurrentUser(list);
		// }

		this.projectsToday = list.filter(s => {
			return new Date(s['date']).toDateString() == date1.toDateString() || (
        s['dist'] && new Date(s['dist']).toDateString() == date1.toDateString());
		});
	}

	filterCurrentWeek(list){
		let date1 = new Date();  
		date1.setDate(date1.getDate() - date1.getDay() + 1)
    date1.setHours(0);   date1.setMinutes(0);
		let date2 = new Date();
		date2.setDate(date2.getDate() + 7 - date2.getDay())
    date2.setHours(0);   date2.setMinutes(0);

		// if (this.type != 'all'){
		// 	list = this.filterCurrentUser(list);
		// }

		this.projectsWeek = list.filter(s => {
			return new Date(s['date']) >= date1 && new Date(s['date']) <= date2 || 
      (s['dist'] && new Date(s['dist']) >= date1 && new Date(s['dist']) <= date2);
		});

    this.projectsWeek = this.projectsWeek.sort((a: any, b: any) => {
      if (a['date'] < b['date']) {
          return -1;
      } else if (a['date'] > b['date']) {
          return 1;
      } else {
          return 0;
      }
  });
	}

  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '1 min ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'primary',
  //   redirectTo: '/notification/1'
  // },
  // {
  //   text: 'New user registered.',
  //   subText: '5 min ago',
  //   icon: 'mdi mdi-account-plus',
  //   bgColor: 'info',
  //   redirectTo: '/notification/2'
  // },
  // {
  //   text: 'Cristina Pride',
  //   subText: 'Hi, How are you? What about our next meeting',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'success',
  //   redirectTo: '/notification/3'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '2 days ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'danger',
  //   redirectTo: '/notification/4'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '1 min ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'primary',
  //   redirectTo: '/notification/5'
  // },
  // {
  //   text: 'New user registered.',
  //   subText: '5 min ago',
  //   icon: 'mdi mdi-account-plus',
  //   bgColor: 'info',
  //   redirectTo: '/notification/6'
  // },
  // {
  //   text: 'Cristina Pride',
  //   subText: 'Hi, How are you? What about our next meeting',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'success',
  //   redirectTo: '/notification/7'
  // },
  // {
  //   text: 'Caleb Flakelar commented on Admin',
  //   subText: '2 days ago',
  //   icon: 'mdi mdi-comment-account-outline',
  //   bgColor: 'danger',
  //   redirectTo: '/notification/8'
  // }
}
