let listmenu = [
    {
        title: 'Dashboard',
        code: 'dashboard',
        category: 'dashboard',
        path: '/dashboard'
    },
    {
        title: 'Proiecte',
        code: 'projects',
        category: 'projects',
        path: '/tools'
    }
];

export const menu = {
    listmenu: listmenu
}