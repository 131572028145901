import {Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';

import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TabsService } from '../tabs/tabs.service';
import { TabModel } from '../tabs/tab.model';
import {BaseService} from 'src/app/pages/_services/base.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { menu } from './menu';

// =======
// import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
// import {TabsService} from '../tabs/tabs.service';
// import {TabModel} from '../tabs/tab.model';
// import {AuthenticationService} from "../../core/services/auth.service";
// import {CookieService} from "../../core/services/cookie.service";
// >>>>>>> 3546520b2c33306c44ceb2a000ae6f4e0ad269ab

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [BaseService]
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() isCondensed = false;
    searchTerm = '';
    menuDataFiltered = [];
    modules = menu.listmenu;
    currentItem =     {
        // title: 'Proiecte',
        // code: 'projects',
        // category: 'projects',
        // path: '/tools'
    }

    listModulesUser;
    menuData = [];

    menu: any;


    @ViewChild('sideMenu', {static: false}) sideMenu: ElementRef;

    constructor(
        private router: Router, 
        private tabService: TabsService, 
        private service:BaseService, 
        private authenticationService: AuthenticationService,
        private cookieService: CookieService) {

        this.openTab(this.currentItem)
    }

    ngOnInit() {

        const currentUser = this.authenticationService.currentUser();

        if (currentUser && currentUser['id'] != 1){
            this.service.findAllItemsEntity('userxmodules').subscribe((all) => {
                this.listModulesUser = all.filter(function (entry) {
                    return entry['userid'] == currentUser['id'];
                });

                let result = menu.listmenu;
                for(let i1=0; i1 < result.length; i1++){
                    // if (result[i1].category && result[i1].children && result[i1].children.length > 0){
                    //     for(let i2=0; i2 < result[i1].children.length; i2++){
                    //         let found = this.listModulesUser.filter(function (entry) {
                    //             return entry['code'] == result[i1].children[i2]['path'];
                    //         });
                    //         if (!found || found.length == 0){
                    //             result[i1].children.splice(i2,1)
                    //             i2 = i2-1;
                    //         }
                    //     }

                    //     let found = this.listModulesUser.filter(function (entry) {
                    //         return entry['category'].toLowerCase() == result[i1].category.toLowerCase()
                    //     });
                    //     if (!found || found.length == 0){
                    //         result.splice(i1,1)
                    //         i1 = i1-1;
                    //     }
                    // }
                    if (result[i1].category && result[i1].path){
                        let found = this.listModulesUser.filter(function (entry) {
                            return entry['code'] == result[i1]['path'];
                        });
                        if (!found || found.length == 0){
                            result.splice(i1,1)
                            i1 = i1-1;
                        }
                    }
                    // else if (result[i1].category && result[i1].main){
                    //     let found = this.listModulesUser.filter(function (entry) {
                    //         return entry['category'].toLowerCase() == result[i1].category.toLowerCase()  || 
                    //         entry['category'].toLowerCase().indexOf(result[i1].category.toLowerCase()+'-') >= 0
                    //     });
                    //     if (!found || found.length == 0){
                    //         result.splice(i1,1)
                    //         i1 = i1-1;
                    //     }
                    // }
                    else if (!result[i1]['main'] && !result[i1].path) {
                        result.splice(i1,1)
                        i1 = i1-1;
                    }
                };
        
                this.menuData = menu.listmenu;
                this.filterMenu('')
                
            });
        }
        else {
            this.menuData = menu.listmenu;
            this.filterMenu('')
        }

        // this.modules = JSON.parse(this.cookieService.getCookie('currentUser')).modules.map((module) => {
        //     return module.toLowerCase();
        // });
        const tempMenu = [];
        // this.menuData.forEach((menu) => {
        //     if (menu.code) {
        //         if (this.modules.includes(menu.code.toLowerCase())) tempMenu.push(menu);
        //     }
        //     if (menu.children) {
        //         const childrenTemp = [];
        //         menu.children.forEach((submeniu) => {
        //             if (submeniu['code']) {
        //                 if (this.modules.includes(submeniu['code'].toLowerCase())) {
        //                     childrenTemp.push(submeniu);
        //                     let newMenu = {title: '', children: []};
        //                     newMenu.title = menu.title;
        //                     newMenu.children = childrenTemp;
        //                     // menu.children = childrenTemp;
        //                     tempMenu.push(newMenu);
        //                 }
        //             }
        //         });

        //     }
        // });
        console.log(tempMenu);
        // this.menuData = tempMenu;
        this.menuDataFiltered = this.menuData;
        const searchterm = 'dashboard';
        this.router.events
            .subscribe((value) => {
                if (value instanceof NavigationStart) {
                    this.removeDropdown();
                }
                if (value instanceof NavigationEnd) {
                    // this.menuDataFiltered = [];
                    // this.menuDataFiltered = this.menuData;
                    // setTimeout(()=>{
                    //     this.menu.dispose();
                    //     this.menu = new MetisMenu(this.sideMenu.nativeElement);
                    this._activateMenuDropdown();
                    // });
                }
            });
    }

    filterMenu(searchterm: string) {
        
        if (searchterm !== '') {
            this.menuDataFiltered = this.menuData
                .filter(menu => menu.children ? menu.children.some(x => x.title.toLowerCase().includes(searchterm.toLowerCase())) || menu.title.toLowerCase().includes(searchterm.toLowerCase()) : menu.title.toLowerCase().includes(searchterm.toLowerCase()));
        } else {
            this.menuDataFiltered = this.menuData;
        }
        setTimeout(() => {
            this.menu.dispose();
            this.menu = new MetisMenu(this.sideMenu.nativeElement);
        });
    }

    // menuFilter(query):any[] {
    //     //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    //     for(let i = 0; i < this.menuData.length; i++) {
    //         let menu = this.menuData[i];
    //         if(menu.title.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //             this.menuDataFiltered.push(menu);
    //         }
    //     }
    //     return filtered;
    // }

    ngAfterViewInit() {
        if (this.menu) {
            this.menu.dispose();
        }
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        this._activateMenuDropdown();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);
        if (!this.isCondensed && this.sideMenu || this.isCondensed) {
            setTimeout(() => {
                this.menu = new MetisMenu(this.sideMenu.nativeElement);
                this._activateMenuDropdown();
            });
        } else if (this.menu) {
            this.menu.dispose();
        }
    }

    openTab(menuItem: any) {
        if (menuItem && menuItem['main'])
        return;

        this.currentItem = menuItem;

        const tab: TabModel = new class implements TabModel {
            path: string;
            title: string;
        };
        tab.path = menuItem.path;
        tab.title = menuItem.title;
        
        if (this.detectMob())
        this.tabService.tabs.forEach((element,index) => {
            this.tabService.tabs.splice(index,1)
        });

        this.tabService.addTab(tab);
        this.router.navigateByUrl(tab.path);

        if (this.isMobile())
        this.onToggleMobileMenu();
    }

    onToggleMobileMenu() {
        document.body.classList.toggle('sidebar-enable');
        if (!this.isMobile()) {
            document.body.classList.toggle('enlarged');
            this.isCondensed = !this.isCondensed;
        }
    }

    isMobile() {
        const ua = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
    }

    /**
     * small sidebar
     */
    smallSidebar() {
        document.body.classList.add('left-side-menu-sm');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('topbar-light');
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('enlarged');
    }

    /**
     * Dark sidebar
     */
    darkSidebar() {
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.add('left-side-menu-dark');
        document.body.classList.remove('topbar-light');
        document.body.classList.remove('boxed-layout');
    }

    /**
     * Light Topbar
     */
    lightTopbar() {
        document.body.classList.add('topbar-light');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.remove('boxed-layout');

    }

    /**
     * Sidebar collapsed
     */
    sidebarCollapsed() {
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.toggle('enlarged');
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('topbar-light');
    }

    /**
     * Boxed Layout
     */
    boxedLayout() {
        document.body.classList.add('boxed-layout');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.add('enlarged');
        document.body.classList.remove('left-side-menu-sm');
    }

    /**
     * Activates the menu dropdown
     */
    _activateMenuDropdown() {
        const links = document.getElementsByClassName('side-nav-link-ref');
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]['pathname']) {
                menuItemEl = links[i];
                console.log(menuItemEl);
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.add('active');

            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add('active');

                const parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.add('in');
                }

                const parent3El = parent2El.parentElement;
                if (parent3El) {
                    console.log(parent3El);
                    parent3El.classList.add('active');
                    parent3El.firstChild.classList.add('active');
                }
            }
        }
    }

    removeDropdown() {
        const links = document.getElementsByClassName('side-nav-link-ref');
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]['pathname']) {
                menuItemEl = links[i];
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.remove('active');

            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.remove('active');

                const parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.remove('in');
                }

                const parent3El = parent2El.parentElement;
                if (parent3El) {
                    parent3El.classList.remove('active');
                    parent3El.firstChild.classList.remove('active');
                }
            }
        }
    }

    detectMob() {
        return (window.innerWidth <= 768);
    }

}
