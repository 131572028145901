import { Component, OnInit, AfterViewInit, TemplateRef  } from '@angular/core';
import { DataService } from 'src/app/pages/_services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-tokenization',
  templateUrl: './tokenization.component.html',
  styleUrls: ['./tokenization.component.scss']
})
export class TokenizationComponent implements OnInit, AfterViewInit{

  list;
  term;
  selected=1;

  currentUser;

  userExist;userInfo;
  loaded;

  modalRef: BsModalRef;

  submit;email;message;type;
  
  constructor(
    private dataService: DataService,
    public router: Router,
    private modalService: BsModalService) {
  }

  ngOnInit() {
    this.loaduser();
  }

  ngAfterViewInit() {

  }

  setarticles(){
    //delete this.selected;
  }

  async loaduser(){
    if (this.dataService.coinbase){

      //this.dataService.initweb3();

      this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

          if (data && data['email']){
            this.dataService.userEmail = data['email'];
          }

          // this.dataService.saveItem(this.dataService.tableusers, {id: this.currentUser.uid, coderef: false} ).then((data) => {

          // })

        if (!this.currentUser['coderef']){

          this.currentUser['coderef'] = this.currentUser.id.substring(3,7)+UUID.UUID().substring(1,7);
          this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, coderef: this.currentUser['coderef']} ).then((data) => {

          })
        }

        // let sub1 = this.dataService.getList(this.dataService.tableinvites).subscribe((data)=>{
        //   sub1.unsubscribe();

        //   let data1 = data.filter(function (entry) {
        //     return entry['invite'] == self.currentUser['coderef'];
        //   });

        //   this.invitesall = data1;

        //   let data2 = data.filter(function (entry) {
        //     return entry['invite'] == self.currentUser['coderef'] && entry['valid'];
        //   });

        //   this.invitesvalid = data2;
        // })

        this.loaded = true;
      })

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
      this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();


      // this.activated1 = await this.dataService.tokenContract.methods.usersActiveB3Levels(this.dataService.coinbase, 1).call();
      // this.activated2 = await this.dataService.tokenContract.methods.usersActiveB3Levels(this.dataService.coinbase, 2).call();
      // this.activated3 = await this.dataService.tokenContract.methods.usersActiveB3Levels(this.dataService.coinbase, 3).call();

    }
  }

  async login(){
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist){
      this.loaduser();
    }
    else
    this.router.navigate(['/login']);

  }

  openModal(template: TemplateRef<any>, type) {
    delete this.type;
    delete this.message;
    this.type = type;

    this.submit = false;

    this.modalRef = this.modalService.show(template,{
      backdrop: 'static',
    });
 }

  sendmsg(){
    this.submit = true;

    if (!this.email || !this.message)
    return;

    this.dataService.saveSubItem(this.dataService.tablemsg, this.currentUser.uid, "messages", {id: UUID.UUID(), email: this.email, content: this.message, type: this.type} ).then((data) => {
      delete this.email;
      delete this.message;
      
      this.modalRef.hide()
    })
  }

}
